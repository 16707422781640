const environment = process?.env.REACT_APP_CONFIG_ENVIRONMENT || 'production';

const baseConfig = {
	env: 'default',
	obRole: 'admin',
	host: 'https://api.dotsco.co/public',
	S3: 'https://dotsco.s3.me-south-1.amazonaws.com/',
	pdfLink: () => getAPIURL('getPdfLink'),
	profile: () => getAPIURL('profile'),
	userContactAsVcf: () => getAPIURL('getUserContactAsVcf'),
	bannerConfig: {
		android: 'https://play.google.com/store/apps/details?id=com.infinitech.dots',
		ios: 'https://apps.apple.com/in/app/dots-tap-my-dots/id1599017164',
		windows: 'https://dotsco.co/#/',
		kindle: 'https://dotsco.co/#/',
	},
};

const production = {
  ...baseConfig, ... {
    env: "production"
  }
};

const staging = {
	...baseConfig,
	...{
		env: 'staging',
		host: 'https://stage.api.dotsco.co/public'
	},
};

const development = {
	...baseConfig,
	...{
		env: 'development',
		host: 'http://localhost:3000/public',
	},
};

const config = {
	development,
	staging,
	production,
};
console.log(environment);
export default config[environment];

export const API_ROOT = `${config[environment].host}`;

export const getAPIURL = (resourceURL) => `${API_ROOT}/${resourceURL}`;
